import React, { useMemo } from 'react'
import qs from 'query-string'

import Link from 'next/link'

import { useQuery } from '../../context/route'

interface LinkChangePathProps {
    className?: string
    children?: React.ReactNode
    style?: any
    onClick?: () => void
    href?: string
    hash?: string
}

const LinkChangePath = ({
    className,
    children,
    style,
    onClick,
    href,
    hash,
}: LinkChangePathProps) => {
    const query = useQuery()

    const fullHref = useMemo(() => {
        return `${href}${!!Object.keys(query).length ? '?' : ''}${(query && qs.stringify(query)) || ''
            }${hash || ''}`
    }, [href, query, hash])

    return (
        <Link href={fullHref}>
            <a style={style} className={className} onClick={onClick}>
                {children}
            </a>
        </Link>
    )
}

export default LinkChangePath
