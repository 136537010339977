import { Location } from '@zupr/types/fo'
import { locationUrl } from '@zupr/utils/url'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo } from 'react'

import DomainContext from '../../../context/domain'
import { useHistory } from '../../../context/route'
import { useIsMobile, useIsSmall } from '../../../context/ux'
import Image from '../../../shared/components/image'
import Trans from '../../../shared/components/trans'
import Carousel from '../../../shared/image/carousel'
import LocationClassifications from '../../../shared/location/classifications'
import LocationFeatures from '../../../shared/location/features'
import NowOpenLabel from '../../../shared/location/hours/label'
import ActionLinks from '../../../shared/location/links/action-links'
import Anchor from '../../../shared/router/anchor'
import Logo from './images/logo'
import Menu from './menu'

import { ReactComponent as GridIcon } from '../../../../svg/grid.svg'

import '../../../../scss/react/pages/location-header.scss'

interface Props {
    location: Location
}

type MenuHeight =
    | 78 // single bar desktop
    | 132 // double bar desktop
    | 60 // mobile

const LocationHeader = ({ location }: Props) => {
    const isSmall = useIsSmall()
    const route = useRouter()
    const referer = useHistory()
    const { type } = useContext(DomainContext)
    const isMobile = useIsMobile()

    let menuHeight: MenuHeight = type === 'location' ? 78 : 132
    if (isMobile) menuHeight = 60

    const images = useMemo(() => {
        const exteriorImages = location.images.filter(
            (image) => image.tag === 'Exterior'
        )
        const interiorImages = location.images.filter(
            (image) => image.tag !== 'Exterior'
        )

        let images = [
            ...exteriorImages.slice(0, 1), // slice the first exterior
            ...interiorImages,
            ...exteriorImages.slice(1), // slice all except first
        ]

        if (location.preferences.nr_of_images === 1) return images.slice(0, 1) // max 1
        if (images.length < 5) return images.slice(0, 2) // max 2
        if (location.preferences.nr_of_images === 2) return images.slice(0, 2) // max 2
        return images.slice(0, 5) // max 5
    }, [location.images, location.preferences])

    useEffect(() => {
        const hasHash = route.asPath.includes('#')

        // if it already has #location
        if (hasHash) return null

        // if we have no referer it is the first page
        if (!referer) return null

        // if we come from any other page than location page we don't want to scroll yet
        // when domain type is location whe always want to scroll to #location with a referer
        const url = new URL(referer)
        if (type !== 'location' && !url.pathname.includes(location.id)) {
            return null
        }

        route.replace(`${route.asPath}#location`)
    }, [location.id, route, referer, type])

    return (
        <React.Fragment>
            <div className="location-header-images">
                {isSmall && (
                    <Carousel
                        alt={location.title}
                        indicator
                        images={location.images.sort((a, b) =>
                            a.tag > b.tag ? 1 : 0
                        )}
                    />
                )}

                {!isSmall && !images.length && (
                    <Carousel alt={location.title} images={[]} />
                )}

                {!isSmall && !!images.length && (
                    <div
                        className={`location-header-images-grid location-header-images-${images.length}`}
                    >
                        {images.map((image, index) => (
                            <figure key={image.image_provider_hash}>
                                <Image
                                    image={image}
                                    alt={location.title}
                                    large={index === 0}
                                    eager
                                />
                            </figure>
                        ))}
                    </div>
                )}

                {!isSmall && !!images.length && (
                    <Link href={locationUrl(location, 'photos')}>
                        <a className="more">
                            <GridIcon />
                            <Trans label="Alle fotos" dutch />
                        </a>
                    </Link>
                )}
            </div>
            <div className="location-header">
                {location.logo_image_provider_hash && (
                    <Logo
                        offset={menuHeight}
                        location={location}
                        className={classNames({
                            'logo-round':
                                location.preferences.shape_of_logo === 'round',
                        })}
                        image={{
                            image_provider_hash:
                                location.logo_image_provider_hash,
                        }}
                        alt={location.title}
                    />
                )}
                <div className="location-info">
                    <div className="location-title-and-adress">
                        <h3>{location.title}</h3>
                        <address>
                            {location.address} {location.number}
                            {' · '}
                            {location.city}
                            {!!location?.classificationList?.length && (
                                <React.Fragment>
                                    {' · '}
                                    <LocationClassifications
                                        classificationList={
                                            location.classificationList
                                        }
                                    />
                                </React.Fragment>
                            )}
                        </address>
                        <div className="location-properties">
                            <NowOpenLabel
                                opening_hours={location.opening_hours}
                            />
                            <LocationFeatures {...location} />
                        </div>
                    </div>
                    <ActionLinks location={location} share />
                </div>
            </div>
            <Anchor
                id="location"
                offset={isMobile ? menuHeight : menuHeight + 100}
            />
            <div className="location-menu">
                <Menu location={location} />
            </div>
        </React.Fragment>
    )
}
export default LocationHeader
