import { Location } from '@zupr/types/fo'
import { formatPaymentMethods } from '@zupr/utils/paymentmethods'

import Trans from '../../../../shared/components/trans'
import GridBlock from '../../../components/grid/block'
import GridColumn from '../../../components/grid/column'
import GridPage from '../../../components/grid/page'
import AddressAndLocationBlock from '../blocks/address-and-map'
import ContactBlock from '../blocks/contact'
import SummaryBlock from '../blocks/summary'

import '../../../../../scss/react/pages/location-view.scss'

interface LocationInformationProps {
    location: Location
}

const LocationInformation = ({ location }: LocationInformationProps) => (
    <GridPage leftWide className="location-view">
        <GridColumn swapOrder>
            <SummaryBlock location={location} />
            <ContactBlock location={location} />
        </GridColumn>
        <GridColumn>
            <AddressAndLocationBlock wide location={location} />
            <GridBlock title="Betalen">
                <h5>
                    <Trans label="Betaalmogelijkheden in de winkel" dutch />
                </h5>
                <p>{formatPaymentMethods(location.payment_methods)}</p>
                <h5>
                    <Trans label="Betaalmogelijkheden online" dutch />
                </h5>
                <p>{formatPaymentMethods({ iDeal: true })}</p>
            </GridBlock>
        </GridColumn>
    </GridPage>
)

export default LocationInformation
