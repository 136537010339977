import { useRouter } from 'next/router'
import { useCallback, useContext } from 'react'

import Trans from '../trans'

import RouteContext from '../../../context/route'

const Limit = ({ limit }) => {
    const { query, push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    const thelimit = query.limit || limit || 24

    const onChange = useCallback(
        (ev) => push(changeQuery({ limit: ev.target.value })),
        [changeQuery, push]
    )

    return (
        <div className="pagination-limit">
            <label htmlFor="limit-selector">
                <Trans label="Show" />
            </label>
            <select
                name="limit-selector"
                className="small"
                value={thelimit}
                onChange={onChange}
            >
                <option value={12}>{`12`}</option>
                <option value={24}>{`24`}</option>
                <option value={48}>{`48`}</option>
                {/* <option value={'∞'}>{trans({ label: "pagination.limit.all" })}</option> */}
            </select>
        </div>
    )
}

Limit.defaultProps = {
    limit: 24,
}

export default Limit
