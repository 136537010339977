import React, { useCallback, useContext } from 'react'
import { t } from '@zupr/i18n'
import { ProductLocation } from '@zupr/types/fo'

import useFeatures from '../../features/hooks/features'

import StockIndicator from '../../../shared/product-location/stock/indicator/icon'
import StockLabel from '../../../shared/product-location/stock/indicator/label'

import {
    useShoppingAddProductLocation,
    useShoppingListItem,
} from '../../features/shopping-list/hooks/item'
import ShoppingListContext from '../../../context/shopping-list'
import RouteContext from '../../../context/route'
import { IconCheck } from '../../../shared/components/icon'

import { ReactComponent as Plus } from '../../../../svg/plus.svg'

import '../../../../scss/react/product-location/product-location-stock.scss'

interface StockProps {
    onClick?: () => void
    productLocation: ProductLocation
}

const ProductLocationStock = ({ productLocation, onClick }: StockProps) => {
    const { trackEvent } = useContext(RouteContext)

    const { delivery, payAndCollect, reservation, shoppable } =
        useFeatures(productLocation)

    const { onOpenSidebar } = useContext(ShoppingListContext)

    const { onUpdate, quantity, isOnList, availableToAdd } =
        useShoppingListItem(productLocation)

    const onAdd = useShoppingAddProductLocation(productLocation)

    const handleAdd = useCallback(() => {
        if (onClick) onClick() // callback

        if (isOnList) {
            onUpdate({
                quantity: Math.min(quantity + 1, availableToAdd),
            })
        } else {
            onAdd()
            trackEvent({
                id: productLocation.location.id,
                action: 'addProductToShoppingList',
                context: productLocation.product.id,
            })
            trackEvent({
                id: productLocation.product.id,
                action: 'addProductToShoppingList',
                context: productLocation.location.id,
            })
        }

        onOpenSidebar()
    }, [
        availableToAdd,
        isOnList,
        onAdd,
        onClick,
        onOpenSidebar,
        onUpdate,
        productLocation.location.id,
        productLocation.product.id,
        quantity,
        trackEvent,
    ])

    return (
        <div className="product-location-stock">
            <p>
                <StockIndicator {...productLocation} />
                <strong>
                    <StockLabel {...productLocation} />
                </strong>
            </p>

            {shoppable && (
                <>
                    <button
                        className="btn btn-large btn-success"
                        onClick={handleAdd}
                    >
                        <Plus />
                        <span>{t('Add to cart')}</span>
                    </button>
                    <div>
                        <ul>
                            <li>
                                <IconCheck
                                    question={
                                        productLocation.stock_prefs ===
                                        'call_for_stock'
                                    }
                                    checked={
                                        productLocation.stock_prefs !==
                                        'call_for_stock'
                                    }
                                />
                                {t('In de winkel te koop', 'dutch')}
                            </li>
                            <li>
                                <IconCheck checked={reservation} />
                                {t('Reserveren', 'dutch')}
                            </li>
                            <li>
                                <IconCheck checked={payAndCollect} />
                                {t('Click & Collect', 'dutch')}
                            </li>
                            <li>
                                <IconCheck checked={delivery} />
                                {t('Bezorgen', 'dutch')}
                                {!!delivery && '*'}
                            </li>
                        </ul>
                        {!!delivery && (
                            <small>
                                {'*'}
                                {t('Afhankelijk van je postcode', 'dutch')}
                            </small>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default ProductLocationStock
