import { useContext, useMemo } from 'react'
import { ProductLocation } from '@zupr/types/fo'
import AreaContext from '../../../context/domain'

const useFeatures = ({
    allow_delivery,
    allow_reservation,
    allow_pay_and_collect,
    delivery_areas,
    delivery,
    stock_prefs,
}: ProductLocation) => {
    const { shoppingAreaSlug } = useContext(AreaContext)

    return useMemo(
        () => ({
            shoppable: stock_prefs !== 'not_in_stock', // can you put item in shopping list
            reservation: !!allow_reservation,
            payAndCollect: !!allow_pay_and_collect,
            delivery:
                allow_delivery ||
                delivery === 'self' ||
                delivery === 'platform_self' ||
                (!!delivery_areas && delivery_areas.includes(shoppingAreaSlug)),
        }),
        [
            allow_delivery,
            allow_pay_and_collect,
            allow_reservation,
            delivery,
            delivery_areas,
            shoppingAreaSlug,
            stock_prefs,
        ]
    )
}

export default useFeatures
