import React, { useContext, useMemo } from 'react'
import { Aggregations, Variables } from '@zupr/types/fo'

import DomainContext from '../../../../context/domain'

import Trans from '../../../../shared/components/trans'
import { FilterSelect } from '../../../components/search/filter/multiselect'
import { aggregationKeys, filterKeys } from '@zupr/next/helpers/products'

interface Props {
    aggregations: Aggregations
    variables: Variables
}

const FeatureFilters = ({ aggregations, variables }: Props) => {
    const { shoppingAreaSlug } = useContext(DomainContext)

    const features = useMemo(() => {
        const deliverableBucket = aggregations.data?.[
            aggregationKeys.deliverable
        ]?.buckets.find(({ key_as_string }) => key_as_string === 'true')

        const collectableBucket = aggregations.data?.[
            aggregationKeys.collectable
        ]?.buckets.find(({ key_as_string }) => key_as_string === 'true')

        const reservableBucket = aggregations.data?.[
            aggregationKeys.reservable
        ]?.buckets.find(({ key_as_string }) => key_as_string === 'true')

        return {
            deliverable:
                deliverableBucket?.[`reverse.${aggregationKeys.deliverable}`]
                    .doc_count,
            collectable:
                collectableBucket?.[`reverse.${aggregationKeys.collectable}`]
                    .doc_count,
            reservable:
                reservableBucket?.[`reverse.${aggregationKeys.reservable}`]
                    .doc_count,
        }
    }, [aggregations.data])

    if (!features.reservable && !features.collectable && !features.deliverable)
        return null

    return (
        <div className="sidebar-filter">
            <div className="filter-header">
                <h4>
                    <Trans label="Afhalen of bezorgen" dutch />
                </h4>
            </div>
            <div className="multiselect-filter">
                {(!!features.reservable ||
                    variables[filterKeys.reservable]) && (
                        <FilterSelect
                            query={{
                                reservable: variables[filterKeys.reservable]
                                    ? null
                                    : 1,
                            }}
                            name="reservable"
                            checked={!!variables[filterKeys.reservable]}
                        >
                            <Trans label="Reserve" />
                        </FilterSelect>
                    )}
                {(!!features.collectable ||
                    variables[filterKeys.collectable]) && (
                        <FilterSelect
                            query={{
                                collectable: variables[filterKeys.collectable]
                                    ? null
                                    : 1,
                            }}
                            name="collectable"
                            checked={!!variables[filterKeys.collectable]}
                        >
                            <Trans label="Click & Collect" />
                        </FilterSelect>
                    )}
                {(!!features.deliverable ||
                    variables[filterKeys.deliverable]) && (
                        <FilterSelect
                            query={{
                                deliverable: variables[filterKeys.deliverable]
                                    ? null
                                    : `${shoppingAreaSlug},self`,
                            }}
                            name="deliverable"
                            checked={!!variables[filterKeys.deliverable]}
                        >
                            <Trans label="Deliver" />
                            {'*'}
                        </FilterSelect>
                    )}
            </div>
            {(!!features.deliverable || variables[filterKeys.deliverable]) && (
                <small>
                    {'*'}
                    <Trans label="Afhankelijk van je postcode" dutch />
                </small>
            )}
        </div>
    )
}

export default FeatureFilters
