import { t } from '@zupr/i18n'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import RouteContext from '../../../context/route'

import '../../../../scss/react/components/pagination.scss'

const PageSelector = ({ currentPage, pages, limit }) => {
    const { push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    return (
        <select
            value={currentPage}
            onChange={(ev) =>
                push(
                    changeQuery({
                        offset: `${limit * parseInt(ev.target.value, 10)}`,
                    })
                )
            }
        >
            {[...Array(pages || 1)].map((x, i) => (
                <option key={i} value={i}>
                    {t('%{page} van %{pages}', {
                        page: i + 1,
                        pages: Math.max(pages || 0, 1),
                    })}
                </option>
            ))}
        </select>
    )
}

export default PageSelector
