import { useMarkdown } from '@zupr/markdown/utils'
import { ProductsAndAggregations } from '@zupr/types/next'
import qs from 'query-string'
import { useEffect } from 'react'

interface Props {
    filter: ProductsAndAggregations['filter']
    activeCategory: ProductsAndAggregations['activeCategory']
    categoryDescription: string | null
}

const CategoryDescription = ({
    activeCategory,
    categoryDescription,
    filter,
}: Props) => {
    const category = useMarkdown(categoryDescription || '')

    // generating a discription takes too long so we genereate it async for the next request
    useEffect(() => {
        if (!activeCategory) return
        if (categoryDescription) return
        // generate the desciption for the next request
        fetch(
            `/api/category/${activeCategory.slug}/description?${qs.stringify(
                filter.variables
            )}`
        )
    }, [activeCategory, categoryDescription, filter.variables])

    if (!categoryDescription) return null

    return (
        <div className="grid-intro">
            <h1>{activeCategory.name}</h1>
            {category}
        </div>
    )
}

export default CategoryDescription
