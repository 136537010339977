import { Location } from '@zupr/types/fo'
import { ProductsAndAggregations } from '@zupr/types/next'
import { locationUrl } from '@zupr/utils/url'
import React from 'react'

import { useQuery } from '../../../../context/route'
import { useIsMobile } from '../../../../context/ux'
import PaginationListFooter from '../../../../shared/components/pagination/footer'
import NoResults from '../../../../shared/search/no-results'
import GridBlock from '../../../components/grid/block'
import GridColumn from '../../../components/grid/column'
import GridPage from '../../../components/grid/page'
import { ProductAndLocation } from '../../../components/search/result/product-location'
import CategoryDescription from '../../products/components/category-description'
import ProductListSearch from '../../products/components/search'
import Filters from '../../products/filters'
import ProductsCategories from '../../products/filters/category'
import SortAndPagination, { PaginationAndFilterButton } from './components/sort'

export interface LocationProductsProps extends ProductsAndAggregations {
    location: Location
    categoryDescription: string | null
}

const LocationProducts = ({
    location,
    products,
    filter,
    ...props
}: LocationProductsProps) => {
    const isMobile = useIsMobile()
    const query = useQuery()
    return (
        <GridPage rightWide>
            {!isMobile && (
                <GridColumn>
                    <GridBlock>
                        <ProductsCategories
                            useSlugs
                            baseUrl={locationUrl(location, 'products')}
                            {...props}
                        />
                    </GridBlock>
                    <GridBlock>
                        <Filters
                            baseUrl={locationUrl(location, 'products')}
                            productsAtLocation
                            products={products}
                            filter={filter}
                            {...props}
                        />
                    </GridBlock>
                </GridColumn>
            )}
            <GridBlock className="grid-list grid-list-products">
                <CategoryDescription filter={filter} {...props} />
                <ProductListSearch />
                {!isMobile && <SortAndPagination products={products} />}
                {isMobile && (
                    <PaginationAndFilterButton
                        location={location}
                        products={products}
                        filter={filter}
                        {...props}
                    />
                )}
                {products && (
                    <React.Fragment>
                        {!products.count && (
                            <NoResults term={query.autocomplete} />
                        )}
                        {!!products.count && (
                            <ul>
                                {products.results.map((variation, index) => (
                                    <ProductAndLocation
                                        index={index}
                                        key={`product-row-${variation.id}`}
                                        variation={variation}
                                        location={location}
                                    />
                                ))}
                            </ul>
                        )}
                        <PaginationListFooter count={products.count} />
                    </React.Fragment>
                )}
            </GridBlock>
        </GridPage>
    )
}

export default LocationProducts
