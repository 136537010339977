import { t } from '@zupr/i18n'
import Link from 'next/link'
import React, { useCallback } from 'react'

import { Brand, Location, Product, ProductLocation } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'

import { formatSubtitle } from '@zupr/utils/product'
import { locationUrl, productLocationUrl } from '@zupr/utils/url'
import ChangePath from '../../../../shared/router/link-change-path'

import StockButtons from '../../../components/product-location/buttons'

import { NewlineBreak } from '../../../../shared/components/read-more'

import Share from '../../../../shared/components/share'
import Trans from '../../../../shared/components/trans'
import Carousel from '../../../../shared/image/carousel'

import GridBlock from '../../../components/grid/block'
import GridColumn from '../../../components/grid/column'
import GridPage from '../../../components/grid/page'

import PriceAndUnit from '../../../../shared/product-location/price-and-unit'
import AdditionalData from '../../../../shared/product/additional-data'
import Variations from '../../../../shared/product/components/variations'
import ProductLinks from '../../../../shared/product/links'

import { useIsMobile } from '../../../../context/ux'

import { ReactComponent as Chevron } from '../../../../../svg/chevron.svg'
import GridBreadcrumbs, {
    CategoryBreadcrumbs,
} from '../../../components/grid/breadcrumbs'

import '../../../../../scss/react/pages/location-product.scss'

export const MobileBreadcrumbs = ({ baseUrl, productBreadcrumbs }) => {
    // on mobile show simplified breadcrumbs
    // when we are in categories

    if (!!productBreadcrumbs?.length) {
        return (
            <React.Fragment>
                {productBreadcrumbs.slice(-1).map((category) => (
                    <Link
                        key={`mobile-breadcrumb-${category.slug}`}
                        href={`${baseUrl}?category=${category.slug}`}
                    >
                        <a className="grid-breadcrumbs-back">
                            <Chevron />
                            {category.name}
                        </a>
                    </Link>
                ))}
            </React.Fragment>
        )
    }

    return (
        <Link href={baseUrl}>
            <a className="grid-breadcrumbs-back">
                <Chevron />
                {t('Products')}
            </a>
        </Link>
    )
}

export interface LocationProductProps {
    location: Location
    product: Product
    brand: Brand
    productLocation: ProductLocation
    productVariations: List<Product>
    productVariationsAtLocation: List<ProductLocation>
    productBreadcrumbs: any
}

const LocationProduct = ({
    location,
    product,
    brand,
    productLocation,
    productVariations,
    productVariationsAtLocation,
    productBreadcrumbs,
}: LocationProductProps) => {
    const isMobile = useIsMobile()

    const handleVariationRoute = useCallback(
        ({ id }) => ({
            hash: 'product',
            pathname: productLocationUrl({
                location,
                product: { id },
            }),
        }),
        [location]
    )

    return (
        <GridPage leftWide className="location-product">
            {!isMobile && (
                <GridBreadcrumbs>
                    <ChangePath
                        href={locationUrl(location, 'products')}
                        className="grid-breadcrumbs-back"
                    >
                        <Chevron />
                        <Trans label="Back to assortment" />
                    </ChangePath>

                    {productBreadcrumbs && (
                        <div className="grid-breadcrumbs-path">
                            <span>{'|'}</span>
                            <CategoryBreadcrumbs
                                baseUrl={locationUrl(location, 'products')}
                                categories={productBreadcrumbs}
                            />
                        </div>
                    )}

                    {!!brand && (
                        <Link
                            href={`${locationUrl(
                                location,
                                'products'
                            )}?brands=${brand.id}`}
                        >
                            <a className="grid-breadcrumbs-more">
                                {t(`Bekijk al onze producten van %{brand}`, {
                                    brand: brand.title,
                                })}
                            </a>
                        </Link>
                    )}
                </GridBreadcrumbs>
            )}

            <GridBlock swapOrder>
                {isMobile && (
                    <MobileBreadcrumbs
                        baseUrl={locationUrl(location, 'products')}
                        productBreadcrumbs={productBreadcrumbs}
                    />
                )}

                <div className="product-titles">
                    <div>
                        <h1>{product.title}</h1>
                        <h2>{brand && formatSubtitle(product, brand)}</h2>
                    </div>
                    <Share url={productLocationUrl(productLocation)} />
                </div>

                {isMobile && (
                    <Carousel
                        className="product-images"
                        images={product.images}
                        alt={product.title}
                        navigation
                        zoom
                        thumbnails
                    />
                )}

                <Variations
                    onVariationRoute={handleVariationRoute}
                    availableVariations={productVariationsAtLocation}
                    product={product}
                    variations={productVariations}
                />

                <div className="price-and-unit">
                    <PriceAndUnit {...productLocation} />
                </div>

                <StockButtons productLocation={productLocation} />
            </GridBlock>

            <GridColumn>
                {!isMobile && (
                    <GridBlock>
                        <Carousel
                            className="product-images"
                            images={product.images}
                            alt={product.title}
                            navigation
                            zoom
                            thumbnails
                        />
                    </GridBlock>
                )}
                <GridBlock collapsable title="Productinformatie">
                    {product?.description !== productLocation?.description && (
                        <React.Fragment>
                            <h4>
                                <Trans label="Description" />
                            </h4>
                            <p>
                                <NewlineBreak text={product.description} />
                            </p>
                        </React.Fragment>
                    )}

                    {productLocation?.description && (
                        <React.Fragment>
                            <h4>
                                <Trans label="Comments by seller" />
                            </h4>
                            <p>
                                <NewlineBreak
                                    text={productLocation.description}
                                />
                            </p>
                        </React.Fragment>
                    )}

                    <ProductLinks product={product} brand={brand} />
                </GridBlock>
                <GridBlock collapsable title="Productspecificaties">
                    <AdditionalData product={product} />
                </GridBlock>
            </GridColumn>
        </GridPage>
    )
}
export default LocationProduct
