import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo } from 'react'
import sluggify from 'slug'

import { Aggregations, Category, Filter, Product } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'

import { t } from '@zupr/i18n'
import { aggregationKeys, filterKeys } from '@zupr/next/helpers/products'
import { formatPrice } from '@zupr/utils/price'

import PaginationSort from '../../../shared/components/pagination/sort'
import Trans from '../../../shared/components/trans'

import AreaContext from '../../../context/domain'
import RouteContext from '../../../context/route'

import CollapsableFilter from '../../components/search/filter/collapse'
import FilterContainer from '../../components/search/filter/container'
import RemoveFilter from '../../components/search/filter/remove'
import RemoveAllFilters from '../../components/search/filter/remove-all'

import ProductsCategories from './filters/category'
import Color from './filters/color'
import FeatureFilters from './filters/features'
import PriceFilter from './filters/price-filter'

import FilterLinks, { FilterLink } from '../../components/search/filter/link'
import Multiselect from '../../components/search/filter/multiselect'

import FilterSearch, {
    RemoveSearchFilters,
} from '../../components/search/filter/search'

import { useIsMobile } from '../../../context/ux'

import '../../../../scss/react/filter/filters.scss'
import DiscountFilter from './filters/discount'
import ShopperLocationFilter, {
    RemoveShopperLocation,
} from './filters/shopper-location'
import StockFilters from './filters/stock'

const filterPriceBuckets = (aggregations, key) => {
    const filtered = aggregations?.filtered || aggregations
    return filtered?.[key]?.buckets.filter((bucket) => bucket.key >= 0)
}

const sortSizes = (buckets) => {
    return buckets.sort((a, b) => a.key >= b.key)
}

interface Props {
    aggregations: Aggregations
    products: List<Product>
    categoryBreadcrumbs: Category[]
    filter: Filter
    categories: Category[]
    activeCategory: Category
    productsAtLocation?: boolean
    baseUrl?: string
    useSlugs?: boolean
}

const ProductsFilters = ({
    aggregations,
    products,
    baseUrl,
    useSlugs,
    categoryBreadcrumbs,
    filter,
    productsAtLocation,
    categories,
    activeCategory,
}: Props) => {
    const { query, push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    const { shoppingAreaSlug, themes } = useContext(AreaContext)
    const isMobile = useIsMobile()

    const { variables } = filter

    const price = variables[filterKeys.price]
    const priceValue = price && {
        min: parseInt(price.split(':')[0], 10),
        max: parseInt(price.split(':')[1], 10),
    }

    // if value is not false these items are removable in the filter
    const removeable = {
        ordering: query.ordering && t(query.ordering, 'filter'),
        deliverable: t('Deliver'),
        collectable: t('Click & Collect'),
        reservable: t('Reserve'),
        stock: t('In stock'),
    }

    const filterThemes = useCallback(
        (bucket) => bucket.key && themes?.includes(bucket.key),
        [themes]
    )

    const themeAggregations = useMemo(
        () =>
            (aggregations?.data &&
                !variables[filterKeys.theme] &&
                aggregations.data[aggregationKeys.themes]?.buckets?.filter(
                    filterThemes
                )) ||
            [],
        [aggregations, filterThemes, variables]
    )

    const priceBuckets = filterPriceBuckets(
        aggregations.data,
        aggregationKeys.price
    )

    const data = aggregations.data || {}

    const onHandlePrice = useCallback(
        ({ min, max }) => {
            push(
                changeQuery({
                    price: `${min}:${max}`,
                })
            )
        },
        [changeQuery, push]
    )

    return (
        <FilterContainer
            resultCount={products?.count}
            filterCount={filter.filterCount}
            renderFilters={
                <div className="filters">
                    {filter.filterCount > 0 && (
                        <div className="sidebar-filter">
                            <div className="filter-header">
                                <h4>
                                    <Trans dutch label="Gekozen filters" />
                                </h4>
                                <RemoveAllFilters
                                    href={baseUrl}
                                    filterKeys={filterKeys}
                                    query={
                                        !!variables[filterKeys.box] && {
                                            box: 'false',
                                        }
                                    }
                                />
                            </div>

                            {variables[filterKeys.theme] && (
                                <RemoveFilter href={baseUrl} filter="theme">
                                    {t(variables[filterKeys.theme], 'themes')}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.discount] && (
                                <RemoveFilter href={baseUrl} filter="discount">
                                    {t('Actieproducten', 'dutch')}
                                </RemoveFilter>
                            )}

                            {Object.keys(removeable)
                                .filter((key) => !!query[key])
                                .map((key) => (
                                    <RemoveFilter filter={key}>
                                        {removeable[key]}
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.price] && (
                                <RemoveFilter filter="price">
                                    <Trans label="Price" />
                                    {` (${formatPrice(
                                        priceValue.min,
                                        'EUR',
                                        'NL'
                                    )} - ${formatPrice(
                                        priceValue.max,
                                        'EUR',
                                        'NL'
                                    )})`}
                                </RemoveFilter>
                            )}

                            {variables[filterKeys.color]
                                ?.split(',')
                                .map((color) => (
                                    <RemoveFilter
                                        key={color}
                                        filter="color"
                                        removeFromValue={color}
                                        value={variables[filterKeys.color]}
                                    >
                                        <Trans label={color} context="colors" />
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.size]
                                ?.split(',')
                                .map((size) => (
                                    <RemoveFilter
                                        key={size}
                                        filter="size"
                                        removeFromValue={size}
                                        value={variables[filterKeys.size]}
                                    >
                                        <Trans label="Size" />
                                        {': '}
                                        {size}
                                    </RemoveFilter>
                                ))}

                            {variables[filterKeys.brands] && (
                                <RemoveSearchFilters
                                    url="fo/brand"
                                    filter="brands"
                                    selected={variables[filterKeys.brands]}
                                />
                            )}

                            {variables[filterKeys.box] && (
                                <RemoveShopperLocation />
                            )}

                            {variables[filterKeys.locations] && (
                                <RemoveSearchFilters
                                    url="fo/location"
                                    filter="locations"
                                    selected={variables[filterKeys.locations]}
                                />
                            )}

                            {variables[filterKeys.demographic]
                                ?.split(',')
                                .map((demographic) => (
                                    <RemoveFilter
                                        key={demographic}
                                        filter="demographic"
                                        removeFromValue={demographic}
                                        value={
                                            variables[filterKeys.demographic]
                                        }
                                    >
                                        <Trans label={demographic} />
                                    </RemoveFilter>
                                ))}
                        </div>
                    )}

                    {isMobile && (
                        <div className="sidebar-filter">
                            <PaginationSort
                                defaultOrdering="-product_locations.created"
                                choices={[
                                    'product_locations.price',
                                    '-product_locations.price',
                                    '-product_locations.created',
                                ]}
                            />
                        </div>
                    )}

                    <StockFilters query={query} />

                    <FeatureFilters
                        aggregations={aggregations}
                        variables={variables}
                    />

                    {!productsAtLocation && (
                        <ShopperLocationFilter variables={variables} />
                    )}

                    <DiscountFilter
                        aggregations={aggregations}
                        variables={variables}
                    />

                    {isMobile && (
                        <ProductsCategories
                            baseUrl={baseUrl}
                            useSlugs={useSlugs}
                            categories={categories}
                            activeCategory={activeCategory}
                            categoryBreadcrumbs={categoryBreadcrumbs}
                        />
                    )}

                    {filter.filterCount > 0 &&
                        data[aggregationKeys.demographic] && (
                            <CollapsableFilter header="Doelgroep">
                                <FilterLinks
                                    value={variables[filterKeys.demographic]}
                                    filterKey="demographic"
                                    aggregationKey={aggregationKeys.demographic}
                                    buckets={
                                        data[aggregationKeys.demographic]
                                            .buckets
                                    }
                                    formatLabel={(demographic) => (
                                        <Trans label={demographic} />
                                    )}
                                />
                            </CollapsableFilter>
                        )}

                    {/* No location filter on location products page */}
                    {!variables[filterKeys.location] && (
                        <CollapsableFilter
                            startCollapsed={!variables[filterKeys.locations]}
                            header="Store"
                        >
                            <FilterSearch
                                url="fo/location"
                                variables={{
                                    shopping_areas: shoppingAreaSlug,
                                }}
                                filter="locations"
                                selected={variables[filterKeys.locations]}
                                searchKey="search"
                            />
                        </CollapsableFilter>
                    )}

                    {themeAggregations.length >= 1 && (
                        <CollapsableFilter header="Themes">
                            {themeAggregations.map((bucket) => {
                                const count =
                                    bucket['reverse.product_locations.themes']
                                        .doc_count

                                if (!useSlugs) {
                                    return (
                                        <FilterLink
                                            key={bucket.key}
                                            query={{ theme: bucket.key }}
                                            href={baseUrl}
                                            count={count}
                                        >
                                            {t(bucket.key, 'themes')}
                                        </FilterLink>
                                    )
                                }
                                return (
                                    <FilterLink
                                        key={bucket.key}
                                        href={`/${sluggify(
                                            t(bucket.key, 'themes')
                                        )}`}
                                        count={count}
                                    >
                                        {t(bucket.key, 'themes')}
                                    </FilterLink>
                                )
                            })}
                        </CollapsableFilter>
                    )}

                    {filter.filterCount > 0 &&
                        aggregationKeys.size &&
                        data[aggregationKeys.size] && (
                            <CollapsableFilter
                                startCollapsed={!variables[filterKeys.size]}
                                header="Sizes"
                            >
                                <Multiselect
                                    value={variables[filterKeys.size]}
                                    filterKey="size"
                                    aggregationKey={aggregationKeys.size}
                                    buckets={sortSizes(
                                        data[aggregationKeys.size].buckets
                                    )}
                                />
                            </CollapsableFilter>
                        )}

                    {filter.filterCount > 0 &&
                        data[aggregationKeys.materials] && (
                            <CollapsableFilter
                                startCollapsed={
                                    !variables[filterKeys.materials]
                                }
                                header="Materials"
                            >
                                <Multiselect
                                    value={variables[filterKeys.materials]}
                                    filterKey="materials"
                                    aggregationKey={aggregationKeys.materials}
                                    formatLabel={(label) =>
                                        t(label, 'material')
                                    }
                                    buckets={sortSizes(
                                        data[aggregationKeys.materials].buckets
                                    )}
                                />
                            </CollapsableFilter>
                        )}

                    <CollapsableFilter
                        startCollapsed={!variables[filterKeys.brands]}
                        header="Brand"
                    >
                        <FilterSearch
                            url="fo/brand"
                            filter="brands"
                            selected={variables[filterKeys.brands]}
                            aggregationKey={aggregationKeys.brand}
                            buckets={data[aggregationKeys.brand]?.buckets}
                        />
                    </CollapsableFilter>

                    {filter.filterCount > 0 && data[aggregationKeys.color] && (
                        <CollapsableFilter
                            startCollapsed={!variables[filterKeys.color]}
                            header="Color"
                        >
                            <Color
                                value={variables[filterKeys.color]}
                                filterKey="color"
                                aggregationKey={aggregationKeys.color}
                                buckets={data[aggregationKeys.color].buckets}
                                formatLabel={(color) => (
                                    <Trans label={color} context="colors" />
                                )}
                            />
                        </CollapsableFilter>
                    )}

                    {!!priceBuckets?.length && (
                        <CollapsableFilter header="Price">
                            <PriceFilter
                                key={price || 'pricefilter'}
                                value={priceValue}
                                buckets={priceBuckets}
                                filterKey="price"
                                aggregationKey={aggregationKeys.price}
                                onChange={onHandlePrice}
                            />
                        </CollapsableFilter>
                    )}
                </div>
            }
        />
    )
}

ProductsFilters.defaultProps = {
    baseUrl: '/producten',
    useSlugs: false,
}

export default ProductsFilters
