import React from 'react'
import { locationUrl } from '@zupr/utils/url'
import { Location } from '@zupr/types/fo'

import Share from '../../components/share'

import { RouteAction } from './route'
import { PhoneAction } from './phone'
import { EmailAction } from './email'
import { WebsiteAction } from './website'

import '../../../../scss/react/components/action-links.scss'

interface ActionLinksProps {
    location: Location
    share?: boolean
}

const ActionLinks = ({ location, share }: ActionLinksProps) => {
    return (
        <div className="location-action-links">
            {location.phone && <PhoneAction location={location} />}
            {location.email && <EmailAction location={location} />}
            {location.website && <WebsiteAction location={location} />}
            <RouteAction location={location} />
            {share && <Share url={locationUrl(location, 'home')} />}
        </div>
    )
}

export default ActionLinks
