import React from 'react'
import { useRouter } from 'next/router'

import Trans from '../trans'

// interface Props {
//     count?: number
//     offset?: number
//     limit?: number
// }

const Offset = ({ count, offset, limit }) => {
    const { query } = useRouter()

    limit = Number(query.limit || limit || 24)
    count = Number(query.count || count || 0)
    offset = Number(query.offset || offset || 0)

    return (
        <div className="offset">
            {!!count && (
                <Trans
                    label="%{start}-%{end} of %{count}"
                    values={{
                        count,
                        start: Number(count && offset) + 1 || 0,
                        end: Math.min(offset + limit, count),
                    }}
                />
            )}
        </div>
    )
}

Offset.defaultProps = {
    limit: 24,
    offset: 0,
    count: 0,
}

export default Offset
